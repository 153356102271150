import repository from './repository'

const resource = 'order-transactions'

export default {
  index(params) {
    return repository.get(resource, { params })
  },
  exportV1() {
    return repository.get(`${resource}/export`)
  },
  getByOrder(orderId) {
    return repository.get(`${resource}/${orderId}/by-order`)
  },
  store(data) {
    return repository.post(resource, data)
  },
  update(id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
}
