<template lang="pug">
  v-container
    v-row
      v-col(cols="3")
        v-card
          v-container.pt-5
            v-autocomplete(
              dense
              v-model="reportType"
              label="Report"
              :items="['revenues', 'collections']"
              hide-details
            ).pa-0.ma-0
      v-col(cols="3")
        div(
          style="width: 100%; height: 100%; display: flex; align-items: center"
        )
          v-btn(
              color="green"
              dense
              dark
              @click="exportCollectionExcel"
              :loading="exportingExcel"
            ) Export
      v-col(cols="12")
        v-card.mb-1
          v-container
            v-overlay(
              :value="revenueSourceSaleGetting"
              absolute
            )
              v-progress-circular(
                size="60"
                color="white"
                indeterminate
              )
            revenue-source-sales-chart(
              :sales-data="revenueSourceSaleList"
              :interval="interval"
              :date="this.date"
              :report-type="reportType"
            )
    v-row
      v-col(cols="3")
        v-card
          v-container
            v-simple-table
              template(v-slot:default)
                thead
                  tr
                    th Revenue Source
                    th Amount Today
                tbody
                  tr(v-for="(item, index) in revenueSourceSaleToday" :key="index")
                    th {{ item.label }}
                    td.text-right {{ item.amount.phpFormat() }}
                  tr(v-if="revenueSourceSaleGetting")
                    td.text-center(colspan="2")
                      v-progress-circular(
                        size="20"
                        color="primary"
                        indeterminate
                      )
      v-col(cols="3")
        v-row
          v-col(cols="12")
            v-card
              v-slider(
                :tick-labels="sliderLabels"
                v-model="intervalIndex"
                step="1"
                :max="4"
                ticks
                tick-size="0"
                thumb-label
              )
                template(v-slot:thumb-label="{ value }")
                  small {{ sliderLabels[value] }}
          v-col(cols="12")
            v-card
              v-container
                v-text-field(
                  hide-details
                  label="Date"
                  type="date"
                  v-model="date"
                )
          
</template>
<script>
import revenueSourceRepository from '@/repositories/revenue-source.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import orderTransactionRepository from '@/repositories/order-transaction.repository'
import { requestVars } from '@/libs/api-helper.extra'

let reportType = 'revenues'
const [revenueSourceSaleVars, revenueSourceSaleVarNames] = requestVars({ identifier: 'revenue-source-sale' })

const revenueSourceSaleHandler = new VueRequestHandler(null, revenueSourceSaleVarNames)

export default {
  name: 'RevenueReports',
  components: {
    revenueSourceSalesChart: () => import('@/components/revenue-reports/RevenueSourceSalesChart'),
  },
  data() {
    return {
      ...revenueSourceSaleVars,
      exportingExcel: false,
      reportType: 'revenues',
      intervalIndex: 0,
      date: Window.getCurrentDate(),
      sliderLabels: ['1W', '15D', '1M', '1Y', '5Y'],
    }
  },
  created() {
    this.getrevenueSourceSale()
  },
  watch: {
    interval() {
      this.getrevenueSourceSale()
    },
    date() {
      this.getrevenueSourceSale()
    },
    reportType(val) {
      reportType = val
      this.getrevenueSourceSale()
    },
  },
  computed: {
    interval() {
      return this.sliderLabels[this.intervalIndex]
    },
    revenueSourceSaleToday() {
      const attribute = `daily_${reportType}`
      return this.revenueSourceSaleList.map(item => {
        const date = Window.getCurrentDate()
        const revenue = item[attribute].find(drItem => drItem.date === date)
        if (!revenue) return { amount: 0, label: item.code }
        return { amount: revenue.amount, label: item.code }
      })
    },
  },
  methods: {
    getrevenueSourceSale() {
      const handler = revenueSourceSaleHandler
      const repository = revenueSourceRepository[this.reportType]
      const params = { date: this.date }
      handler.setVue(this)
      handler.execute(repository, [this.interval, params])
    },
    async exportCollectionExcel() {
      try {
        if (this.exportingExcel) return
        this.exportingExcel = true
        const data = await orderTransactionRepository.exportV1
      } catch (e) {
        alert(e.message)
      } finally {
        this.exportingExcel = false
      }
    },
  },
}
</script>
